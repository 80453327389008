import React from "react";

const footer = () => {
  return (
    <>
      <br></br>
      <br></br>
      <br></br>
    </>
  );
};

export default footer;
