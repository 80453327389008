import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import Login from "./accounts/login";

const Profile = () => {
  const user = useSelector((state) => state.auth.user);
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);

  if (!isLoggedIn) {
    return <Login />;
  }
  return (
    <ProfileContainer>
      <p className="name">{user.name ? user.name : user.email.split("@")[0]}</p>
    </ProfileContainer>
  );
};

export default Profile;

const ProfileContainer = styled.div``;
