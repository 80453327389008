import { createSlice } from "@reduxjs/toolkit";

const mediaSlice = createSlice({
  name: "media",
  initialState: {
    allMedia: [],
    isUpdated: true,
  },
  reducers: {
    setAllMedia(state, action) {
      state.allMedia = action.payload;
    },

    refreshMedia(state) {
      state.isUpdated = !state.isUpdated;
    },
  },
});

export const mediaActions = mediaSlice.actions;

export default mediaSlice;
