import React, { useEffect } from "react";
import { useInView } from "react-intersection-observer";
import styled from "styled-components";
import { motion, useScroll, useSpring } from "framer-motion";
import Frame from "../../../../../../media/frame.png";

const Temp = () => {
  const { scrollYProgress } = useScroll();
  const scaleX = useSpring(scrollYProgress, {
    stiffness: 100,
    damping: 30,
    restDelta: 0.001,
  });
  console.log(scrollYProgress);

  return (
    <Container>
      {" "}
      <motion.img
        style={{
          opacity: scaleX,
        }}
        className="square"
        src={Frame}
      ></motion.img>
    </Container>
  );
};

export default Temp;

const Container = styled.div`
  width: 90vw;
  margin: 0 auto;
  /* height: 200vh; */
  .square {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
  }
  .text {
    z-index: 500;
    margin: auto;
  }
`;
