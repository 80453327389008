import React, { useEffect } from "react";
import { useState } from "react";
import styled from "styled-components";
import ReviewsApi from "../../../../../../api/reviews.api";
import SingleReview from "./singleReview";
import { HiStar } from "react-icons/hi";
import { FcGoogle } from "react-icons/fc";
import { motion } from "framer-motion";

const Reviews = () => {
  const [reviews, setReviews] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [reviewVisible, setReviewVisible] = useState(false);

  useEffect(() => {
    ReviewsApi.allReviews(setReviews, setIsLoading);
  }, []);

  if (isLoading) {
    return <></>;
  }
  let averageRating = 0;

  reviews.map((rev) => {
    averageRating = averageRating + rev.stars;
  });

  averageRating = (averageRating / reviews.length).toFixed(2);

  return (
    <ReviewsContainer>
      <div
        className="heading"
        onClick={() => setReviewVisible((prev) => !prev)}
      >
        <p className="average-rating">
          <HiStar className="icon-star" />
          {averageRating}
        </p>
        <p className="google-icon">
          <FcGoogle className="icon-google" /> All reviews
        </p>
      </div>
      {reviewVisible && (
        <div className="all-reviews">
          {reviews.map((review) => {
            return (
              <motion.div
                initial={{ opacity: 0, scale: 0.8 }}
                whileInView={{
                  x: 0,
                  opacity: 1,
                  scale: 1,
                  rotate: 0,
                }}
                transition={{ duration: 1 }}
              >
                <SingleReview review={review} />
              </motion.div>
            );
          })}
        </div>
      )}
    </ReviewsContainer>
  );
};

export default Reviews;

const ReviewsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 90vw;
  margin: 0 auto;

  .all-reviews {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 10px;
  }
  .average-rating {
    display: flex;
    flex-direction: row;
    gap: 10px;
    font-size: 40px;
    margin: 0;
    align-items: center;
    color: #f3c092;
    font-family: "Amatic SC";
    font-weight: 900;
  }
  .icon-google {
    margin-top: 2px;
  }
  .heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .google-icon {
    font-size: 15px;
    display: flex;
    align-items: center;
    gap: 5px;
  }
`;
