import React, { useState } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import AddressForm from "./addressForm";

const AddressPicker = ({ selectAddress, addresses }) => {
  const [isFormVisible, setIsFormVisible] = useState(false);
  const dispatch = useDispatch();
  return (
    <Picker>
      {/* <p className="title">Pick address</p> */}
      {/* {!(addresses.length < 1) && ( */}
      <button
        className="toggle-form2"
        onClick={() => setIsFormVisible((prev) => !prev)}
      >
        {isFormVisible ? "Close" : "+ Create new"}
      </button>
      {/* )} */}
      {isFormVisible && <AddressForm setFormOpen={setIsFormVisible} />}

      {addresses.length > 0 &&
        addresses.map((address) => {
          return (
            <div className="selector" onClick={() => selectAddress(address)}>
              <p className="address-name">{address.name}</p>
              <p className="address-detail">{address.whatsappNumber}</p>
              <p className="address-detail">{address.fullAddress}</p>
              <p className="address-detail">{address.landmark}</p>
              <p className="address-detail">{address.pincode}</p>
            </div>
          );
        })}
    </Picker>
  );
};

export default AddressPicker;

const Picker = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  /* border: 1px solid #f3c092; */
  border-radius: 10px;
  background-color: black;
  padding: 10px;

  .selector {
    display: flex;
    flex-direction: column;
    background-color: black;
    width: 80vw;
    color: #f3c092;
    border-radius: 10px;
    padding: 10px;
    margin: 0 auto;
    gap: 5px;
    border: 1px solid #434343;
  }
  .address-name {
    font-weight: 900;
    font-size: 17px;
    margin: 0;
    margin-bottom: 5px;
  }
  .address-detail {
    margin: 0;
    font-size: 11px;
  }

  .title {
    text-align: center;
  }
  .toggle-form2 {
    background: none;
    border: none;
    color: #f3c092;
  }
`;
