import React, { useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { RiShoppingBagFill, RiWhatsappFill } from "react-icons/ri";

import { HiMenu } from "react-icons/hi";
import Logo from "../media/logoLight.png";
import { motion } from "framer-motion";

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const navigate = useNavigate();
  return (
    <HeaderContainer>
      <p
        className="header-icon"
        onClick={() => {
          setIsMenuOpen((prev) => !prev);
        }}
      >
        <HiMenu />
      </p>
      <img
        src={Logo}
        alt=""
        className="logo"
        onClick={() => {
          navigate("/shop/illustrations");
        }}
      />
      <p className="header-icon" onClick={() => navigate("/cart")}>
        <RiShoppingBagFill />
      </p>
      {isMenuOpen && (
        <motion.div
          className="menu"
          initial={{ x: -200, opacity: 0 }}
          whileInView={{ x: 0, opacity: 1 }}
          transition={{ duration: 0.6 }}
          onClick={() => {
            setIsMenuOpen((prev) => !prev);
          }}
        >
          <p className="menu-link">X</p>
          <a
            href=""
            className="menu-link"
            onClick={() => {
              setIsMenuOpen((prev) => !prev);
              navigate("/shop/illustrations");
            }}
          >
            Buy Illustrations
          </a>
          {/* <a href="" className="menu-link">
            My Profile
          </a> */}
          <a
            href=""
            className="menu-link"
            onClick={() => {
              setIsMenuOpen((prev) => !prev);
              navigate("/orders");
            }}
          >
            Orders
          </a>
          <a
            href=""
            className="menu-link"
            onClick={() => {
              setIsMenuOpen((prev) => !prev);
              navigate("/contact");
            }}
          >
            Contact
          </a>
          <a
            className="whatsapp"
            href="https://wa.me/message/NI7LLGQNQK2DG1"
            target="_blank"
          >
            Chat <RiWhatsappFill className="icon" />
          </a>
        </motion.div>
      )}
    </HeaderContainer>
  );
};

export default Header;

const HeaderContainer = styled.div`
  display: flex;
  width: 100vw;
  position: fixed;
  height: 60px;
  top: 0;
  left: 0;
  z-index: 1000;
  background-color: black;
  justify-content: space-between;
  .menu {
    position: fixed;
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 100vw;
    background-color: black;
    top: 0;
    align-items: center;
    justify-content: center;
    gap: 20px;
  }
  .menu-link {
    text-decoration: none;
    color: #f3c092;
    background: none;
    border: none;
    cursor: pointer;
  }
  .logo {
    cursor: pointer;
    display: flex;
    margin: 5px auto;
  }
  .header-icon {
    color: white;
    margin: auto 20px;
    font-size: 20px;
  }
  .icon {
    margin-top: 2px;
  }
  .whatsapp {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    background: none;
    border: none;
    background-color: #f3c092;
    padding: 10px;
    border-radius: 5px;
    color: black;
    font-weight: 900;
    font-size: 15px;
    cursor: pointer;
    text-decoration: none;
  }
`;
