import { motion } from "framer-motion";
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import OrderAPI from "../../../../api/order.api";
import SingleOrderMini from "./components/singleOrderMini";

const Orders = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [orders, setOrders] = useState([]);

  useEffect(() => {
    OrderAPI.getOrders(setOrders, setIsLoading);
  }, []);

  if (isLoading) {
    return <></>;
  }

  console.log(orders);

  return (
    <OrdersContainer>
      <p className="title">Orders</p>
      <div className="orders">
        {orders.map((order) => {
          return (
            <motion.Fragment
              initial={{ opacity: 0, scale: 0.8 }}
              whileInView={{
                x: 0,
                opacity: 1,
                scale: 1,
                rotate: 0,
              }}
              transition={{ duration: 1 }}
            >
              <SingleOrderMini order={order} />
            </motion.Fragment>
          );
        })}
      </div>
    </OrdersContainer>
  );
};

export default Orders;
const OrdersContainer = styled.div`
  display: grid;
  width: 90vw;
  margin: 0 auto;
  gap: 20px;

  .title {
    font-size: 30px;
    text-align: center;
    font-family: "Amatic SC";
    font-weight: 900;
    margin: 10px auto;
    color: #f3c092;
  }

  .orders {
    display: grid;
    width: 90vw;
    margin: 0 auto;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
  }
`;
