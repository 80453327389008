import axios from "axios";
import { authActions } from "../store/authSlice";

axios.defaults.withCredentials = true;

// const BASE_URL = "http://localhost:8000/";
const BASE_URL = "https://artsbyrishi-api-776ne.ondigitalocean.app/";

const addToCart = async (data, navigate) => {
  try {
    const response = await axios
      .create()
      .post(`${BASE_URL}illustrations/addtocart`, data);
    if (response.data.success) {
      //   dispatch(mediaActions.setAllMedia(response.data.media));
      console.log("added");
      navigate("/cart");
    }
  } catch (err) {
    console.log(err.message);
  } finally {
    // setIsLoading(false);
  }
};
const registerAndAddToCart = async (data, navigate, dispatch) => {
  try {
    const response = await axios
      .create()
      .post(`${BASE_URL}illustrations/register-and-addtocart`, data);
    if (response.data.success) {
      dispatch(authActions.login());
      navigate("/cart");
    }
  } catch (err) {
    console.log(err.message);
  } finally {
    // setIsLoading(false);
  }
};

// const uploadMedia = async (dispatch, setIsLoading, data, setImage) => {
//   try {
//     const response = await axios.create().post(`${BASE_URL}media/new`, data, {
//       headers: {
//         "content-type": "multipart/form-data",
//       },
//     });
//     if (response.data.success) {
//       dispatch(mediaActions.refreshMedia());
//       setImage({});
//     }
//   } catch (err) {
//     console.log(err.message);
//   } finally {
//     setIsLoading(false);
//   }
// };

// const deleteMedia = async (setIsLoading, mediaID, dispatch) => {
//   try {
//     const response = await axios
//       .create()
//       .delete(`${BASE_URL}media/${mediaID}/delete`, {
//         // headers: {
//         //   "content-type": "multipart/form-data",
//         // },
//       });
//     if (response.data.success) {
//       dispatch(mediaActions.refreshMedia());
//     }
//   } catch (err) {
//     console.log(err.message);
//   } finally {
//     setIsLoading(false);
//   }
// };

const IllustrationAPI = {
  addToCart,
  registerAndAddToCart,
};

export default IllustrationAPI;
