import React, { useState, useEffect } from "react";

import CartAPI from "../../../../api/cart.api";
import RazorpayAPI from "../../../../api/razorpay.api";
import { useDispatch, useSelector } from "react-redux";
import Login from "../../../customer/pages/accounts/login";
import CartItem from "./components/cartItem";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import Checkout from "../checkout/checkout";

const Cart = () => {
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const [isLoading, setIsLoading] = useState(true);
  const isUpdated = useSelector((state) => state.cart.isUpdated);

  const cartData = useSelector((state) => state.cart.cart);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (isLoggedIn) {
      CartAPI.getCartItems(dispatch, setIsLoading);
    }
  }, [isUpdated]);

  const handleCheckout = () => {
    navigate("/checkout");
  };

  if (!isLoggedIn) {
    return <Login />;
  }

  if (isLoading) {
    return <></>;
  }

  return (
    <CartContainer>
      <p className="title">Cart</p>
      <div className="seperator"></div>
      {cartData.illustrations.map((item) => {
        return <CartItem item={item} />;
      })}
      <div className="seperator"></div>
      <div className="details">
      <Checkout />
        {/* <p className="total">{`Total : ${total}`}</p> */}
        <p className="note">Free Delivery | No Extra Charges ❤️</p>
      </div>
      <div className="seperator"></div>
      {/* <button className="checkout-btn" onClick={handleCheckout}>
        Checkout
      </button> */}
    </CartContainer>
  );
};

export default Cart;

const CartContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 90vw;
  margin: 0 auto;
  gap: 20px;

  .title {
    font-size: 30px;
    text-align: center;
    font-family: "Amatic SC";
    font-weight: 900;
    margin: 10px auto;
    color: white;
  }

  .checkout-btn {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: 1px solid black;
    background-color: #f3c092;
    color: black;
    font-weight: 900;
    font-size: 15px;
    border-radius: 10px;
    height: 40px;
    padding: 0 20px;
    cursor: pointer;
  }
  .note {
    text-align: center;
    font-size: 13px;
  }
`;
