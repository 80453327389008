import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { RiWhatsappFill } from "react-icons/ri";
import { FaShippingFast } from "react-icons/fa";
import { HiLocationMarker } from "react-icons/hi";

import styled from "styled-components";
import OrderAPI from "../../../../../api/order.api";
import CartItem from "../../cart/components/cartItem";

const SingleOrder = () => {
  const [order, setOrder] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const orderID = useParams().orderID;
  useEffect(() => {
    OrderAPI.getSingleOrder(setOrder, setIsLoading, orderID);
  }, []);

  const navigate = useNavigate();
  if (isLoading) {
    return <></>;
  }
  let total = 0;
  order.illustrations.map((item) => {
    total = total + item.finalPrice;
  });
  // console.log(order);

  const orderDate = new Date(order.created_at);
  let estimatedDate = new Date();
  estimatedDate =
    ("" + new Date(estimatedDate.setDate(orderDate.getDate() + 9))).substring(
      0,
      3
    ) +
    ", " +
    ("" + new Date(estimatedDate.setDate(orderDate.getDate() + 9))).substring(
      4,
      11
    );

  return (
    <OrderContainer>
      <p className="title">Order Details</p>
      <div className="order-details">
        <p className="detail">{order.total}</p>
      </div>
      <div className="items">
        {order.illustrations.map((item) => {
          return <CartItem item={item} isFixed={true} key={item._id} />;
        })}
      </div>
      <div className="address">
        <p className="address-name">{order.address.name}</p>
        <p className="address-detail">{order.address.whatsappNumber}</p>
        <p className="address-detail">{order.address.fullAddress}</p>
        <p className="address-detail">{order.address.landmark}</p>
        <p className="address-detail">{order.address.pincode}</p>
      </div>
      <div className="details-text">
        <p className="delivery-estimate">
          <FaShippingFast /> {estimatedDate}{" "}
        </p>
        <p className="detail">Rs. {total}</p>
      </div>
      {/* <button className="btn">
        <HiLocationMarker />
        Track Shipment
      </button> */}
      <a
        className="btn"
        href="https://wa.me/message/NI7LLGQNQK2DG1"
        target="_blank"
      >
        <RiWhatsappFill />
        Message on whatsApp
      </a>
    </OrderContainer>
  );
};

export default SingleOrder;

const OrderContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 90vw;
  margin: 0 auto;
  gap: 20px;
  .address {
    display: flex;
    flex-direction: column;
    background-color: black;
    color: #f3c092;
    border-radius: 10px;
    padding: 10px;
    margin: 0 auto;
    gap: 5px;
    border: 1px solid #434343;
  }
  .address-name {
    font-weight: 900;
    font-size: 17px;
    margin: 0;
    margin-bottom: 5px;
  }
  .address-detail {
    margin: 0;
    font-size: 11px;
  }
  .detail {
    margin: 0;
  }
  .btn {
    display: flex;
    gap: 10px;
    background: none;
    background-color: #f3c092;
    font-weight: 900;
    border: 1px solid black;
    border-radius: 7px;
    padding: 7px;
    align-items: center;
    font-size: 15px;
    text-decoration: none;
    color: black;
  }
  .title {
    font-size: 30px;
    text-align: center;
    font-family: "Amatic SC";
    font-weight: 900;
    margin: 10px auto;
    color: white;
  }
  .details-text {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;
