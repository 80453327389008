import axios from "axios";

axios.defaults.withCredentials = true;

// const BASE_URL = "http://localhost:8000/";
const BASE_URL = "https://artsbyrishi-api-776ne.ondigitalocean.app/";

const allReviews = async (setReviews, setIsLoading) => {
  try {
    const response = await axios.create().get(`${BASE_URL}reviews/`);
    if (response.data.success) {
      setReviews(response.data.reviews);
    }
  } catch (err) {
    console.log(err.message);
  } finally {
    setIsLoading(false);
  }
};

const ReviewsAPI = {
  allReviews,
};

export default ReviewsAPI;
