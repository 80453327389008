import React from "react";
import styled from "styled-components";
import { HiArrowSmRight } from "react-icons/hi";
import pattern1 from "../../../../../media/pattern1.jpg";
import pattern2 from "../../../../../media/pattern2.jpg";
import pattern3 from "../../../../../media/pattern3.jpg";
import pattern4 from "../../../../../media/pattern4.jpg";
import pattern5 from "../../../../../media/pattern5.jpg";
import pattern6 from "../../../../../media/pattern6.jpg";
import pattern7 from "../../../../../media/pattern7.jpg";
import pattern8 from "../../../../../media/pattern8.jpg";
import pattern9 from "../../../../../media/pattern9.jpg";
import pattern10 from "../../../../../media/pattern10.jpg";
import pattern11 from "../../../../../media/pattern11.jpg";
import { useNavigate } from "react-router-dom";

const SingleOrderMini = ({ order }) => {
  let total = 0;
  order.illustrations.map((item) => {
    total = total + item.finalPrice;
  });
  const patterns = [
    pattern1,
    pattern2,
    pattern3,
    pattern4,
    pattern5,
    pattern6,
    pattern7,
    pattern8,
    pattern9,
    pattern10,
    pattern11,
  ];

  const navigate = useNavigate();
  const handleOrderCLick = () => {
    navigate(`/orders/${order._id}`);
  };

  return (
    <OrderContainer onClick={handleOrderCLick}>
      <img className="space" src={patterns[Math.floor(Math.random() * 11)]} />
      <div className="order-details">
        <p className="order-tag">
          {order.illustrations.length}{" "}
          {order.illustrations.length === 1 ? "Item" : "Items"}
        </p>
        <p className="order-tag">Paid: Rs {total}</p>
      </div>
      <button className="view-btn">
        View <HiArrowSmRight />
      </button>
    </OrderContainer>
  );
};

export default SingleOrderMini;

const OrderContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 auto;
  background-color: white;
  border-radius: 10px;
  color: black;
  /* box-shadow: -10px 10px #af8050; */

  .order-details {
    display: flex;
    flex-direction: column;
    padding: 0 10px;
    gap: 4px;
    margin: 4px 0;
  }
  .space {
    border-radius: 10px 10px 0 0;
    height: 160px;
    object-position: center;
    object-fit: cover;
    width: 100%;
  }
  .view-btn {
    /* margin-bottom: 5px; */
    display: flex;
    justify-content: center;
    gap: 4px;
    border-radius: 0 0 10px 10px;
    border: none;
    font-weight: 900;
    height: 30px;
    align-items: center;
    /* background-color: #f3c092; */
  }
  .order-tag {
    display: flex;
    margin: 0;
    font-size: 15px;
  }
`;
