import axios from "axios";

axios.defaults.withCredentials = true;

// const BASE_URL = "http://localhost:8000/";
const BASE_URL = "https://artsbyrishi-api-776ne.ondigitalocean.app/";

const getOrders = async (setOrders, setIsLoading) => {
  try {
    const response = await axios.create().get(`${BASE_URL}order/`);
    if (response.data.success) {
      setOrders(response.data.orders);
    }
  } catch (err) {
    console.log(err.message);
  } finally {
    setIsLoading(false);
  }
};
const getSingleOrder = async (setOrder, setIsLoading, orderID) => {
  try {
    const response = await axios.create().get(`${BASE_URL}order/${orderID}`);
    if (response.data.success) {
      setOrder(response.data.order);
    }
  } catch (err) {
    console.log(err.message);
  } finally {
    setIsLoading(false);
  }
};

const OrderAPI = {
  getOrders,
  getSingleOrder,
};

export default OrderAPI;
