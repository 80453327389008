import axios from "axios";

// import { weddingActions } from "../store/weddingSlice";

axios.defaults.withCredentials = true;

// const BASE_URL = "http://localhost:8000/";
const BASE_URL = "https://artsbyrishi-api-776ne.ondigitalocean.app/";

const newOrder = async (address, navigate) => {
  try {
    const response = await axios
      .create()
      .post(`${BASE_URL}order/create-order`, { withCredentials: true });
    const createdOrderId = response.data.orderID;
    const amount = response.data.amount + "";
    const illustrations = response.data.illustrations;
    console.log(illustrations);

    console.log(response.data);
    if (response.data.success) {
      var options = {
        key: process.env.REACT_APP_RAZORPAY_KEY,
        amount: amount,
        currency: "INR",
        name: "Arts By Rishi",
        description: "Test Transaction",
        // image: "https://wedink-images.s3.ap-south-1.amazonaws.com/LOGO.png",
        order_id: createdOrderId,
        handler: async function (response) {
          console.log(illustrations, "2");
          const data = {
            amount: amount,
            createdOrderId: createdOrderId,
            razorpayPaymentId: response.razorpay_payment_id,
            razorpayOrderId: response.razorpay_order_id,
            razorpaySignature: response.razorpay_signature,
            address: address,
            illustrations: illustrations,
          };
          console.log(response);
          const result = await axios.post(`${BASE_URL}order/verify`, data);
          console.log(result.data);

          if (result.data.success) {
            // dispatch(weddingActions.refreshWedding());
            navigate("/orders");
            // setIsSuccess(true);
          } else {
            // setIsFailed(true);
          }
        },

        theme: {
          color: "#AE7288",
        },
      };

      const paymentObject = new window.Razorpay(options);
      paymentObject.open();
    }
  } catch (err) {
    console.log(err.message);
    // setIsFailed(true);
  }
};

const RazorpayAPI = {
  newOrder,
};
export default RazorpayAPI;
