import React, { useState } from "react";
import styled from "styled-components";
import { Navigate, useNavigate } from "react-router-dom";
import { HiArrowNarrowRight } from "react-icons/hi";
import AccountsAPI from "../../../../api/accounts.api";
import { useDispatch, useSelector } from "react-redux";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const loginData = {
    email: email,
    password: password,
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    AccountsAPI.login(loginData, dispatch, setIsLoading);
  };

  const handleToRegister = () => {
    navigate("/register");
  };

  if (isLoading) {
    return <></>;
  }
  if (isLoggedIn) {
    return <Navigate to="/" />;
  }
  return (
    <LoginForm>
      <h2 className="title">Hey, hi</h2>
      <form className="login-form" onSubmit={handleSubmit}>
        <input
          type="email"
          className="input"
          placeholder="Email"
          onChange={(e) => {
            setEmail(e.target.value);
          }}
          value={email}
        />
        <input
          type="password"
          className="input"
          placeholder="Password"
          onChange={(e) => {
            setPassword(e.target.value);
          }}
          value={password}
        />
        <div className="acc-navigations">
          <button
            type="button"
            className="login-btn secondary"
            onClick={handleToRegister}
          >
            New here? <HiArrowNarrowRight />
          </button>
          <button type="submit" className="login-btn" onClick={handleSubmit}>
            Login <HiArrowNarrowRight />
          </button>
        </div>
      </form>
    </LoginForm>
  );
};

export default Login;

const LoginForm = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0;
  min-height: 80vh;
  gap: 30px;

  .acc-navigations {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    gap: 20px;
  }
  .login-form {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .input {
    border-radius: 7px;
    height: 40px;
    border: 2px solid #f3c092;
    /* background-color: #feecf2; */
    background: none;
    padding-left: 10px;
    font-weight: bold;
    color: #f3c092;
    width: 88vw;
  }
  .input:focus {
    padding-left: 10px;
    color: #f3c092;
    font-weight: bold;
  }

  .input:placeholder-shown {
    padding-left: 10px;
    color: #f3c092;
  }
  .title {
    font-weight: bold;
  }

  .login-btn {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    position: sticky;
    bottom: 10px;
    background: none;
    border: 1px solid black;
    background-color: #f3c092;
    color: black;
    font-weight: 900;
    font-size: 15px;
    border-radius: 10px;
    height: 40px;
    padding: 0 20px;
    cursor: pointer;
  }

  .secondary {
    background-color: none;
    background: none;
    color: #f3c092;
    border: 2px solid;
    cursor: pointer;
  }

  @media only screen and (max-width: 600px) {
    .input {
      width: 80vw;
    }
  }
`;
