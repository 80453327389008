import React, { useState } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import Frame from "../../../../../../media/frame.png";

import IllustrationAPI from "../../../../../../api/illustration.api";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const IllustrationForm = () => {
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const user = useSelector((state) => state.auth.user);

  const [facesCount, setFacesCount] = useState(1);
  const [nameToAdd, setNameToAdd] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isFramed, setIsFramed] = useState(true);
  const [newUserForm, setNewUserForm] = useState(false);
  const [frameSize, setFrameSize] = useState("A4");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const addToCart = () => {
    if (isLoggedIn) {
      const data = {
        facesCount,
        nameToAdd,
        type: isFramed ? "Framed" : "Soft Copy",
        frameSize,
      };
      IllustrationAPI.addToCart(data, navigate);
    } else {
      setNewUserForm(true);
    }
  };
  let price = 0;
  price = facesCount * 399;

  if (isFramed) {
    if (frameSize === "A4") {
      price = price + 799;
    }
    if (frameSize === "A5") {
      price = price + 449;
    }
  }

  const registerAndAddToCart = () => {
    if (newUserForm) {
      const formData = {
        facesCount,
        nameToAdd,
        type: isFramed ? "Framed" : "Soft Copy",
        frameSize,
        email: email,
        password: password,
      };
      console.log(formData);
      IllustrationAPI.registerAndAddToCart(formData, navigate, dispatch);
    } else {
      setNewUserForm(true);
    }
  };

  return (
    <Form>
      {/* <p className="product-name">Illustration</p> */}
      <motion.div
        className="img-container"
        animate={{
          x: [
            2, -2, 2, -2, 2, -2, 2, -2, 2, -2, 2, -2, 2, -2, 2, -2, 2, -2, 2,
            -2, 0,
          ],
          y: [
            2, -2, 2, -2, 2, -2, 2, -2, 2, -2, 2, -2, 2, -2, 2, -2, 2, -2, 2,
            -2, 0,
          ],
          opacity: 1,
          scale: 1,
          rotate: [
            1, -1, 1, -1, 1, -1, 1, -1, 1, -1, 1, -1, 1, -1, 1, -1, 1, -1, 1,
            -1, 0,
          ],
        }}
        transition={{ duration: 120 }}
      >
        <motion.img
          // initial={{ x: -200, opacity: 0, scale: 0.8, rotate: -5 }}

          src={Frame}
          alt=""
          className="product-img"
        />
      </motion.div>
      <p className="heading">Number of faces</p>
      <motion.div
        className="type-selector"
        initial={{ opacity: 0, scale: 0.8 }}
        whileInView={{
          x: 0,
          opacity: 1,
          scale: 1,
          rotate: 0,
        }}
        transition={{ duration: 1 }}
      >
        <div
          className={facesCount === 1 ? "type selected" : "type"}
          onClick={() => {
            setFacesCount(1);
          }}
        >
          <p className="type-name">Single</p>
        </div>
        <div
          className={facesCount === 2 ? "type selected" : "type"}
          onClick={() => {
            setFacesCount(2);
          }}
        >
          <p className="type-name">Couple</p>
        </div>
        <div
          className={facesCount === 3 ? "type selected" : "type"}
          onClick={() => {
            setFacesCount(3);
          }}
        >
          <p className="type-name">3 Faces</p>
        </div>
        <div
          className={facesCount === 4 ? "type selected" : "type"}
          onClick={() => {
            setFacesCount(4);
          }}
        >
          <p className="type-name">4 faces</p>
        </div>
        <div
          className={facesCount === 5 ? "type selected" : "type"}
          onClick={() => {
            setFacesCount(5);
          }}
        >
          <p className="type-name">5 Faces</p>
        </div>
      </motion.div>
      <p className="heading">Pick your type</p>
      <motion.div
        className="type-selector"
        initial={{ opacity: 0, scale: 0.8 }}
        whileInView={{
          x: 0,
          opacity: 1,
          scale: 1,
          rotate: 0,
        }}
        transition={{ duration: 1 }}
      >
        <div
          className={isFramed ? "type selected" : "type"}
          onClick={() => {
            setIsFramed(true);
          }}
        >
          <p className="type-name">Framed</p>
        </div>
        <div
          className={!isFramed ? "type selected" : "type"}
          onClick={() => {
            setIsFramed(false);
          }}
        >
          <p className="type-name">Soft Copy</p>
        </div>
      </motion.div>

      {isFramed && (
        <>
          <p className="heading">Size of the frame</p>
          <motion.div
            className="type-selector"
            initial={{ opacity: 0, scale: 0.8 }}
            whileInView={{
              x: 0,
              opacity: 1,
              scale: 1,
              rotate: 0,
            }}
            transition={{ duration: 1 }}
          >
            <div
              className={frameSize === "A4" ? "type selected" : "type"}
              onClick={() => {
                setFrameSize("A4");
              }}
            >
              <p className="type-name">A4</p>
            </div>
            <div
              className={frameSize === "A5" ? "type selected" : "type"}
              onClick={() => {
                setFrameSize("A5");
              }}
            >
              <p className="type-name">A5</p>
            </div>
          </motion.div>
        </>
      )}

      <p className="heading">Add a name (Optional)</p>
      <input
        className="input"
        onChange={(e) => setNameToAdd(e.target.value)}
        value={nameToAdd}
        placeholder="You can enter a name to add if required"
      />
      <div className="register-form">
        {newUserForm && (
          <>
            <p className="register-heading"> Hey, almost there</p>
            <input
              type="email"
              className="input2"
              onChange={(e) => setEmail(e.target.value)}
              value={email}
              placeholder="Email"
            />
            <input
              type="password"
              className="input2"
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Password"
              value={password}
            />
          </>
        )}
        <motion.button
          className="addToCart"
          animate={{
            opacity: 1,
            scale: 1,
          }}
          transition={{ duration: 120 }}
          onClick={newUserForm ? registerAndAddToCart : addToCart}
          style={
            newUserForm
              ? { border: "2px solid black" }
              : { border: "none", margin: 0 }
          }
        >
          Add to cart
          <p className="price">Rs {price}</p>
        </motion.button>
      </div>
    </Form>
  );
};

export default IllustrationForm;

const Form = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  margin-top: 70px;
  width: 90vw;
  .img-container {
    align-items: center;
    display: flex;
  }
  .product-img {
    width: 80%;
    margin: 0 auto;
  }

  .addToCart {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background: none;
    border: 2px solid black;
    background-color: #f3c092;
    color: black;
    font-weight: 900;
    font-size: 15px;
    border-radius: 10px;
    height: 40px;
    padding: 0 20px;
    margin-bottom: 10px;
    cursor: pointer;
  }
  .price {
    margin: 0;
  }
  .type-selector {
    display: flex;
    gap: 10px 20px;
    flex-wrap: wrap;
  }
  .product-name {
    font-size: 30px;
    text-align: center;
    font-family: "Amatic SC";
    font-weight: 900;
    margin: 20px auto;
  }
  .register-form {
    display: flex;
    flex-direction: column;
    background-color: #f3c092;
    gap: 20px;
    border-radius: 10px;
    padding: 0 10px;
    /* position: sticky;
    bottom: 10px; */
    margin-top: 15px;
  }
  .type {
    display: flex;
    border: 1px solid;
    padding: 10px;
    border-radius: 2px;
    cursor: pointer;
    color: #f3c092;
    background-color: black;
  }
  .type-name {
    margin: 0;
    font-size: 15px;
  }
  .heading {
    font-size: 30px;
    font-family: "Amatic SC";
    color: white;
  }

  .selected {
    background-color: #f3c092;
    color: black;
    font-weight: 900;
  }
  .input {
    border-radius: 7px;
    height: 40px;
    border: 2px solid #f3c092;
    /* background-color: #feecf2; */
    background: none;
    padding-left: 10px;
    font-weight: bold;
    color: #f3c092;
    width: 88vw;
  }
  .input:focus {
    padding-left: 10px;
    color: #f3c092;
    font-weight: bold;
  }

  .input:placeholder-shown {
    padding-left: 10px;
    color: #f3c092;
  }
  .input2 {
    border-radius: 7px;
    height: 40px;
    border: none;
    background-color: white;

    padding-left: 10px;
    font-weight: bold;
    color: black;
    /* width: 88vw; */
  }
  .input2:focus {
    padding-left: 10px;
    color: black;
    font-weight: bold;
  }

  .input2::placeholder {
    color: black;
    opacity: 100%;
  }
  .register-heading {
    font-weight: 900;
    color: black;
  }
`;
