import { useState, useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import Routes from "./routes";
import Theme from "./theme";
import { useDispatch, useSelector } from "react-redux";
import Header from "./pages/header";
import Footer from "./pages/footer";
import AccountsAPI from "./api/accounts.api";

function App() {
  const [isLoading, setIsLoading] = useState(true);
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);

  const dispatch = useDispatch();
  const loadScript = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  };

  useEffect(() => {
    AccountsAPI.getUser(setIsLoading, dispatch);
  }, []);

  useEffect(() => {
    loadScript("https://checkout.razorpay.com/v1/checkout.js");
  }, []);
  if (isLoading) {
    return <></>;
  }
  return (
    <Theme>
      <Router>
        <Header />
        <Routes />
        <Footer />
      </Router>
    </Theme>
  );
}

export default App;
