import React, { useState } from "react";
import styled from "styled-components";
import AccountsAPI from "../../../../../api/accounts.api";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";

const Register = () => {
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const registerUser = (e) => {
    e.preventDefault();
    setIsLoading(true);

    const data = {
      email,
      password,
    };
    AccountsAPI.register(dispatch, data, setIsLoading, setIsSuccess);
  };

  const handleToLogin = () => {
    navigate("/login");
  };
  if (isLoggedIn) {
    return <Navigate to="/" />;
  }
  return (
    <RegisterContainer>
      <form className="form-container">
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className="input"
          placeholder="Email"
        />
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          className="input"
          placeholder="Password"
        />
        <div className="acc-navigations">
          <button
            className="btn"
            type="submit"
            onClick={registerUser}
            onSubmit={registerUser}
          >
            Create account
          </button>
          <button className="btn secondary" onClick={handleToLogin}>
            Login
          </button>
        </div>
      </form>
    </RegisterContainer>
  );
};

export default Register;

const RegisterContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 90vw;
  height: 75vh;
  align-items: center;
  justify-content: center;
  margin: 0 auto;

  .form-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 20px;
  }
  .acc-navigations {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 10px;
  }
  .btn {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    position: sticky;
    bottom: 10px;
    background: none;
    border: 1px solid black;
    background-color: #f3c092;
    color: black;
    font-weight: 900;
    font-size: 15px;
    border-radius: 10px;
    height: 40px;
    padding: 0 20px;
    cursor: pointer;
  }

  .secondary {
    background-color: none;
    background: none;
    color: #f3c092;
    border: 2px solid;
    cursor: pointer;
  }

  .input {
    border-radius: 7px;
    height: 40px;
    border: 2px solid #f3c092;
    /* background-color: #feecf2; */
    background: none;
    padding-left: 10px;
    font-weight: bold;
    color: #f3c092;
    width: 88vw;
  }
  .input:focus {
    padding-left: 10px;
    color: #f3c092;
    font-weight: bold;
  }

  .input:placeholder-shown {
    padding-left: 10px;
    color: #f3c092;
  }
`;
