import React from "react";
import { ThemeProvider } from "styled-components";

const theme = {
  backgroundColor: "#",
  titleFontColor: "#",
  contentFontColor: "#",
  buttonBackgroundColor: "#",
  buttonFontColor: "#",
  primaryFont: "",
  secondaryFont: "",
};

const Theme = ({ children }) => (
  <ThemeProvider theme={theme}>{children}</ThemeProvider>
);

export default Theme;
