import React from "react";
import { Routes, Route } from "react-router-dom";
import ShopHome from "./features/shop/pages/home/home";
import Illustrations from "./features/shop/pages/illustrations/pages/illustrations";
import InvitationHome from "./features/invitation/pages/home/home";
import Cart from "./features/shop/pages/cart/cart";
import Login from "./features/customer/pages/accounts/login";
import Register from "./features/customer/pages/accounts/components/register";
import Orders from "./features/shop/pages/orders/orders";
import Checkout from "./features/shop/pages/checkout/checkout";
import Profile from "./features/customer/pages/profile";
import Contact from "./pages/support/contact";
import SingleOrder from "./features/shop/pages/orders/components/singleOrder";
const routes = () => {
  return (
    <Routes>
      <Route exact path="/shop" element={<ShopHome />}></Route>{" "}
      <Route
        exact
        path="/shop/illustrations"
        element={<Illustrations />}
      ></Route>{" "}
      <Route exact path="/" element={<Illustrations />}></Route>{" "}
      <Route exact path="/invitation" element={<InvitationHome />}></Route>{" "}
      <Route exact path="/cart" element={<Cart />}></Route>{" "}
      <Route exact path="/checkout" element={<Checkout />}></Route>{" "}
      <Route exact path="/login" element={<Login />}></Route>{" "}
      <Route exact path="/register" element={<Register />}></Route>{" "}
      <Route exact path="/orders" element={<Orders />}></Route>{" "}
      <Route exact path="/orders/:orderID" element={<SingleOrder />}></Route>{" "}
      <Route exact path="/profile" element={<Profile />}></Route>{" "}
      <Route exact path="/contact" element={<Contact />}></Route>{" "}
    </Routes>
  );
};

export default routes;
