import React from "react";
import styled from "styled-components";
import Frame from "../../../../../media/frame.png";
import { AiFillDelete } from "react-icons/ai";
import { motion } from "framer-motion";

const CartItem = ({ item, isFixed }) => {
  return (
    <ItemContainer>
      <motion.Fragment
        className="container"
        initial={{ opacity: 0, scale: 0.8 }}
        whileInView={{
          x: 0,
          opacity: 1,
          scale: 1,
          rotate: 0,
        }}
        transition={{ duration: 1 }}
      >
        <div className="product-details">
          <img src={Frame} alt="" className="product-thumbnail" />
          <div className="details">
            <p className="product-name">Illustration</p>
            <div className="tags">
              <p className="tag">{`${item.facesCount} ${
                item.facesCount === 1 ? "Face" : "Faces"
              }`}</p>
              <p className="tag">{item.frameSize}</p>
              <p className="tag">{item.nameToAdd}</p>
              <p className="tag">{item.type}</p>
            </div>
            {!isFixed && (
              <button className="delete-btn">
                <AiFillDelete />
              </button>
            )}
          </div>
        </div>
        <div className="remove-and-price">
          <p className="product-price">Rs {item.finalPrice}</p>
        </div>
        <div className="seperator"></div>
      </motion.Fragment>
    </ItemContainer>
  );
};

export default CartItem;

const ItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 90vw;
  color: #f3c092;
  .container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 90vw;
    color: #f3c092;
  }
  .details {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .product-name {
    margin: 10px 0;
  }
  .product-details {
    display: flex;
    flex-direction: row;
    gap: 20px;
  }
  .remove-and-price {
    display: flex;
    flex-direction: column;
  }
  .product-price {
    margin-top: 10;
  }
  .product-thumbnail {
    display: flex;
    width: 100px;
    /* border-radius: 7px; */
    max-height: 100%;
  }
  .tags {
    display: flex;
    gap: 10px;
    flex-direction: row;
    flex-wrap: wrap;
    width: 70%;
  }

  .tag {
    background-color: #f3c092;
    color: black;
    margin: 0;
    padding: 5px;
    font-size: 13px;
    border-radius: 20px;
    text-align: center;
    width: max-content;
  }

  .delete-btn {
    display: flex;
    align-items: center;
    background: none;
    border: none;
    cursor: pointer;
    font-size: 15px;
    color: #f3c092;
  }
`;
