import React from "react";
import styled from "styled-components";

const Contact = () => {
  return <Container></Container>;
};

export default Contact;

const Container = styled.div``;
