import React from "react";
import styled from "styled-components";

import { HiStar } from "react-icons/hi";

const SingleReview = ({ review }) => {
  return (
    <Container>
      <p className="stars">
        {[...Array(review.stars)].map((elementInArray, index) => (
          <HiStar />
        ))}
      </p>
      <div className="texts">
        {review.text && <p className="text">{review.text}</p>}
        <p className="name">{review.name}</p>
      </div>
    </Container>
  );
};

export default SingleReview;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: white;
  color: black;
  padding: 10px;
  border-radius: 10px;
  gap: 10px;
  .texts {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    flex-direction: column;
  }

  .name {
    margin: 0;
    font-weight: 900;
    font-size: 13px;
    text-transform: capitalize;
  }
  .text {
    margin: 0;
    font-size: 14px;
  }
  .stars {
    color: #f3c092;
    margin: 0;
  }
`;
