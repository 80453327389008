import axios from "axios";
import { cartActions } from "../store/cartSlice";

axios.defaults.withCredentials = true;

// const BASE_URL = "http://localhost:8000/";
const BASE_URL = "https://artsbyrishi-api-776ne.ondigitalocean.app/";

const getAddresses = async (dispatch, setIsLoading, selectAddress) => {
  try {
    const response = await axios.create().get(`${BASE_URL}address/`);
    if (response.data.success) {
      dispatch(cartActions.setAddresses(response.data.addresses));

      if (response.data.addresses.length == 1) {
        selectAddress(response.data.addresses[0]);
      }
    }
  } catch (err) {
    console.log(err.message);
  } finally {
    setIsLoading(false);
  }
};
const addAddress = async (dispatch, setIsLoading, data, setFormOpen) => {
  try {
    const response = await axios.create().post(`${BASE_URL}address/new`, data);
    if (response.data.success) {
      dispatch(cartActions.refreshAddress());
      setFormOpen((prev) => !prev);
    }
  } catch (err) {
    console.log(err.message);
  } finally {
    setIsLoading(false);
  }
};

const AddressAPI = {
  getAddresses,
  addAddress,
};

export default AddressAPI;
