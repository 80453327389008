import React, { useState } from "react";
import styled from "styled-components";
import { HiArrowSmDown } from "react-icons/hi";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { motion } from "framer-motion";
import IllustrationForm from "./components/illustrationForm";
import Temp from "./components/temp";
import Reviews from "./components/reviews";

const Illustrations = () => {
  const [formVisible, setFormVisible] = useState(true);

  const toggleForm = () => {
    setFormVisible((prev) => !prev);
  };
  return (
    <Container>
      {/* <div className="form-toggler" onClick={toggleForm}>
        {formVisible ? (
          <p className="toggle-btn">
            View Product <HiArrowSmDown />
          </p>
        ) : (
          <p className="toggle-btn">
            View Product <HiArrowSmDown />
          </p>
        )}
      </div> */}
      {/* <Temp /> */}
      {formVisible && (
        <motion.Fragment
          initial={{ opacity: 0, scale: 0.8, y: -200 }}
          whileInView={{
            y: 0,
            opacity: 1,
            scale: 1,
            rotate: 0,
          }}
          transition={{ duration: 1 }}
        >
          <IllustrationForm />
          <div className="tabs">
            <div className="tab">
              {/* <div className="tab-btn">
                <p className="tab-name">Reviews</p>
                <p className="tab-icon">
                  {reviewVisible ? (
                    <IoIosArrowUp className="icon" />
                  ) : (
                    <IoIosArrowDown className="icon" />
                  )}
                </p>
              </div> */}
            </div>
            <Reviews />
          </div>
        </motion.Fragment>
      )}
    </Container>
  );
};

export default Illustrations;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 90vw;
  margin: 0 auto;

  .toggle-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f3c092;
    border-radius: 3px;
    font-weight: 900;
    color: black;
    height: 35px;
    cursor: pointer;
    margin: 0 auto;
    width: 90vw;
  }
  .form-toggler {
    width: 100vw;
    position: fixed;
    height: 60px;
    top: 0;
    left: 0;
    z-index: 1000;
    margin: 0 auto;
    margin-top: 70px;
  }
  .tab-icon {
    color: white;
    margin: 0;
  }
  .tab-btn {
    display: flex;
    justify-content: space-between;
    /* align-items: center; */
    margin: 0 10px;
    align-items: center;
  }
  .icon {
    margin: 0;
    margin-top: 2px;
  }
  .tab {
    /* border: 1px solid white; */
    margin: 0;
  }
  .tabs {
    display: flex;
    flex-direction: column;
    margin: 20px auto;
    gap: 20px;
  }
  .tab-name {
    margin: 10px 0;
  }
`;
