import React, { useState } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import AddressAPI from "../../../../../api/address.api";

const AddressForm = ({ setFormOpen }) => {
  const [name, setName] = useState("");
  const [landmark, setLandmark] = useState("");
  const [pincode, setPincode] = useState("");
  const [fullAddress, setFullAddress] = useState("");
  const [whatsappNumber, setWhatsappNumber] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const handleSubmit = (e) => {
    e.preventDefault();
    const data = {
      name,
      landmark,
      pincode,
      fullAddress,
      whatsappNumber,
    };
    setIsLoading(true);
    AddressAPI.addAddress(dispatch, setIsLoading, data, setFormOpen);
  };
  return (
    <FormContainer>
      <form className="form" onSubmit={handleSubmit}>
        <input
          className="input"
          value={name}
          onChange={(e) => {
            setName(e.target.value);
          }}
          placeholder="Name"
        />
        <input
          className="input"
          value={landmark}
          onChange={(e) => {
            setLandmark(e.target.value);
          }}
          placeholder="Landmark"
        />
        <input
          className="input"
          value={pincode}
          onChange={(e) => {
            setPincode(e.target.value);
          }}
          placeholder="Pincode"
        />
        <input
          className="input"
          value={fullAddress}
          onChange={(e) => {
            setFullAddress(e.target.value);
          }}
          placeholder="Full Address"
        />
        <input
          className="input"
          value={whatsappNumber}
          onChange={(e) => {
            setWhatsappNumber(e.target.value);
          }}
          placeholder="WhatsApp number"
        />
        <button
          type="submit"
          className="submit-btn"
          onClick={handleSubmit}
          onSubmit={handleSubmit}
        >
          Save
        </button>
      </form>
    </FormContainer>
  );
};

export default AddressForm;

const FormContainer = styled.div`
  .form {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .input {
    border-radius: 7px;
    height: 40px;
    border: 1px solid #f3c092;
    /* background-color: #feecf2; */
    background: none;
    padding-left: 10px;
    font-weight: bold;
    color: #f3c092;
    width: 88vw;
  }
  .input:focus {
    padding-left: 10px;
    color: #f3c092;
    font-weight: bold;
  }

  .input::placeholder {
    color: #f3c092;
    opacity: 100;
  }
  .submit-btn {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: 1px solid black;
    /* background-color: #f3c092; */
    background: white;
    color: black;
    font-weight: 900;
    font-size: 15px;
    border-radius: 10px;
    width: 90vw;
    height: 40px;
    padding: 0 20px;
    cursor: pointer;
  }
`;
