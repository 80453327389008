import axios from "axios";
import { cartActions } from "../store/cartSlice";

axios.defaults.withCredentials = true;

// const BASE_URL = "http://localhost:8000/";
const BASE_URL = "https://artsbyrishi-api-776ne.ondigitalocean.app/";

const getCartItems = async (dispatch, setIsLoading) => {
  try {
    const response = await axios.create().get(`${BASE_URL}cart/`);
    if (response.data.success) {
      dispatch(
        cartActions.setCart({
          cart: response.data.cart,
          illustrations: response.data.illustrations,
        })
      );
    }
  } catch (err) {
    console.log(err.message);
  } finally {
    setIsLoading(false);
  }
};

const CartAPI = {
  getCartItems,
};

export default CartAPI;
