import React, { useState, useEffect } from "react";
import RazorpayAPI from "../../../../api/razorpay.api";
import { useNavigate } from "react-router-dom";
import AddressPicker from "./components/addressPicker";
import styled from "styled-components";
import { TiTick } from "react-icons/ti";
import { useDispatch, useSelector } from "react-redux";
import AddressAPI from "../../../../api/address.api";

const Checkout = () => {
  const [address, setAddress] = useState({});
  const [isPickerVisible, setIsPickerVisible] = useState(false);
  const addresses = useSelector((state) => state.cart.allAddresses);
  const isUpdated = useSelector((state) => state.cart.isUpdated);
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    AddressAPI.getAddresses(dispatch, setIsLoading, setAddress);
  }, [isUpdated]);

  const navigate = useNavigate();

  const selectAddress = (selectedAddress) => {
    setIsPickerVisible((prev) => !prev);
    setAddress(selectedAddress);
  };

  console.log(address);
  const handlePayment = () => {
    RazorpayAPI.newOrder(address, navigate);
  };
  if (isLoading) {
    return <></>;
  }

  return (
    <CheckoutContainer>
      {/* <p className="page-title">Checkout</p> */}
      {/* <p className="heading">Items</p> */}
      {/* <CartDetails /> */}
      <div className="seperator2"></div>
      <div
        className="title-nav"
        onClick={() => setIsPickerVisible((prev) => !prev)}
      >
        <p className="heading">Address</p>
        <button className="toggler">
          {Object.keys(address).length > 0 ? "Change" : "Select"}
        </button>
      </div>
      {Object.keys(address).length > 0 && (
        <div
          className="picked-address"
          onClick={() => setIsPickerVisible((prev) => !prev)}
        >
          <p className="address-name">
            {address.name} <TiTick />{" "}
          </p>
          <p className="address-detail">{address.whatsappNumber}</p>
          <p className="address-detail">{address.fullAddress}</p>
          <p className="address-detail">{address.landmark}</p>
          <p className="address-detail">{address.pincode}</p>
        </div>
      )}
      {isPickerVisible && (
        <AddressPicker
          addresses={addresses}
          selectAddress={selectAddress}
          setAddress={setAddress}
        />
      )}
      <button
        className="payment-btn"
        onClick={
          Object.keys(address).length > 0
            ? handlePayment
            : () => setIsPickerVisible((prev) => !prev)
        }
      >
        {Object.keys(address).length > 0 ? "Pay" : "Select address"}
      </button>
    </CheckoutContainer>
  );
};

export default Checkout;
const CheckoutContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 90vw;
  margin: 0 auto;
  gap: 20px;
  .seperator2 {
    border-bottom: 1px solid;
    width: 100%;
    height: 2px;
    opacity: 10%;
  }
  .title-nav {
    display: flex;
    justify-content: space-between;
  }
  .toggler {
    background: #f3c092;
    color: black;
    font-weight: 900;
    border-radius: 5px;
    border: none;
  }
  .picked-address {
    display: flex;
    flex-direction: column;
    background-color: black;
    width: 90vw;
    color: #f3c092;
    border-radius: 10px;

    margin: 0 auto;
    gap: 10px;
    border: 1px solid;
  }
  .address-name {
    display: flex;
    gap: 5px;
    font-weight: 900;
    font-size: 20px;
    margin: 10px;
    margin-bottom: 5px;
    align-items: center;
  }
  .address-detail {
    margin: 0;
    margin: 0 10px;
    font-size: 13px;
  }
  .page-title {
    font-size: 30px;
    text-align: center;
    font-family: "Amatic SC";
    font-weight: 900;
    margin: 20px auto;
  }
  .heading {
    font-size: 24px;
    font-family: "Amatic SC";
    font-weight: 900;
    margin: 0;
  }
  .payment-btn {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: 1px solid black;
    background-color: #f3c092;
    color: black;
    font-weight: 900;
    font-size: 15px;
    border-radius: 10px;
    /* position: fixed;
    bottom: 20px; */
    width: 90vw;
    height: 40px;
    padding: 0 20px;
    cursor: pointer;
  }
`;
