import { createSlice } from "@reduxjs/toolkit";

const cartSlice = createSlice({
  name: "cart",
  initialState: {
    cart: {},
    isUpdated: false,
    allAddresses: [],
    selectedAddress: [],
  },
  reducers: {
    setCart(state, action) {
      state.cart = action.payload;
    },

    setAddresses(state, action) {
      state.allAddresses = action.payload;
    },
    selectAddress(state, action) {
      state.selectedAddress = action.payload;
    },
    refreshAddress(state) {
      state.isUpdated = !state.isUpdated;
    },
  },
});

export const cartActions = cartSlice.actions;

export default cartSlice;
